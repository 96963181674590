import { getProduct } from '@kissui/helpers/src/catalog'
import { emitCustomEvent } from './events.helpers'
import { removeEmptyValues } from '@kissui/helpers/src/utils'

const raisedByPB = 'page builder'
const raisedByWC = 'Web component'

/**
 * helper method for 'itemDisplay' GTM event
 * @param {*} event : 'itemDisplay' - Mandatory as is
 * @param {*} eventRaisedBy : 'Web component' - Mandatory as is
 * @param {*} eventAction : 'Click' - Action that led to the item display. For example, click
 * @param {*} itemTypes : ['products'] - Mandatory as is
 * @param {*} rootElement : ['nb-slider'] - Root DOM element for item detection. Closest common ancestor element of the inserted/displayed promotions and products. It will help the detection to reduce its performance consumption. Set to 0 (zero) in order to request detection through the entire document.
 */
export const itemDisplay = args => {
    window.gtmDataObject = window.gtmDataObject || []
    const eventData = {
        event: 'itemDisplay',
        eventRaisedBy: raisedByWC,
        eventAction: 'Click',
        itemTypes: ['products'],
        ...args
    }
    window.gtmDataObject.push(eventData)
    emitCustomEvent('itemDisplay', eventData)
}

/**
 *
 * helper method for 'customEvent' GTM event
 * @param {*} args (event, eventRaisedBy, eventCategory, eventAction, eventLabel, nonInteraction)
 */

export const customEvent = args => {
    window.gtmDataObject = window.gtmDataObject || []
    var eventData
    if (args.event_GA4 === undefined || args.event_GA4 === false) {
        eventData = {
            event: 'customEvent',
            eventRaisedBy: raisedByWC,
            eventCategory: 'User Engagement',
            eventAction: 'Click',
            eventLabel: '',
            nonInteraction: 0,
            ...args
        }
    } else {
        eventData = {
            event_raised_by: raisedByPB,
            ...args
        }
    }
    window.gtmDataObject.push(eventData)
    emitCustomEvent('customEvent', eventData)
}

/**
 * Helper method for tracking component interactions, compatible with GA4.
 *
 * @param {String} creative : component raising the event, e.g. 'before_coffees_list', 'before_machines_list', 'before_accessories_list'
 * @param {String} actionType : description of the interaction, e.g 'pdp quick view'
 * @param {String} internationalId : long SKU, e.g. 'erp.pt.b2c/prod/7243.30'
 * @param {String} internationalName : international name of the product, e.g. 'Vertuo Carafe Pour-Over Style Mild'
 * @param {String} productType : type of product, i.e. 'capsule', 'machine', 'accessory'
 * @param {String} range : product range, e.g. 'ispirazione italiana'
 * @param {String} technology : technology, i.e. 'original', 'vertuo'
 * @param {Number} price : price, e.g. .51
 * @param {String} eventAction : description of the action, e.g. 'PDP Quick View'
 *
 * Initally created for PDP Quick View tracking:
 * https://dsu-confluence.nestle.biz/display/DIANA/BEFORE+-+PDP+Quick+View
 * Will probably be rolled out across all components, update this comment accordingly.
 */
export const trackComponentInteraction = ({
    creative = '',
    actionType = '',
    internationalId = '',
    internationalName = '',
    productType = '',
    technology = '',
    category = '',
    rawPrice = '',
    eventAction = ''
}) => {
    window.gtmDataObject = window.gtmDataObject || []
    const eventData = {
        event: 'page_builder_component_interaction',
        event_raised_by: raisedByPB,
        component_name: creative,
        action_type: actionType,
        item_id_event: internationalId,
        item_name_event: internationalName,
        item_category_event: productType,
        item_technology_event: technology,
        item_range_event: category,
        item_price_event: rawPrice,
        eventCategory: 'User Engagement',
        eventAction: eventAction,
        eventLabel: `${productType} - ${technology} - ${internationalName}`
    }
    window.gtmDataObject.push(eventData)
    emitCustomEvent('page_builder_component_interaction', eventData)
}

/**
 *
 * Push 'view_promotion' event for GA 4
 * @param {*} args (event, event_raised_by, ecommerce: {promotion_id, promotion_name, creative_slot, creative_name})
 */
export const viewPromotion = args => {
    const event = 'view_promotion'
    const eventData = {
        event,
        event_raised_by: raisedByPB,
        ecommerce: {}
    }
    if (Object.keys(args).length) {
        const { id = '', creative = '', name = '', position = '' } = args
        eventData.ecommerce = {
            promotion_id: id,
            promotion_name: name,
            creative_slot: position,
            creative_name: creative
        }
    }
    window.gtmDataObject ??= []
    window.gtmDataObject.push(eventData)
    emitCustomEvent(event, eventData)
}

export const handlePromoClick = args => {
    const { campaign, cta_name } = args

    const cleanedData = removeEmptyValues(campaign)
    selectPromotion({ cta_name, ...cleanedData })
}

/**
 *
 * Push 'select_promotion' event for GA 4
 * @param {*} args (event, event_raised_by, cta_name, ecommerce: {promotion_id, promotion_name, creative_slot, creative_name})
 */
export const selectPromotion = args => {
    const event = 'select_promotion'
    const eventData = {
        event: 'select_promotion',
        cta_name: args?.cta_name ?? '(not set)',
        event_raised_by: raisedByPB,
        ecommerce: {}
    }
    if (Object.keys(args).length) {
        const { id = '', creative = '', name = '', position = '' } = args
        eventData.ecommerce = {
            promotion_id: id,
            promotion_name: name,
            creative_slot: position,
            creative_name: creative
        }

        removeEmptyValues(eventData.ecommerce)
    }

    window.gtmDataObject ??= []
    window.gtmDataObject.push(eventData)
    emitCustomEvent(event, eventData)
}

export const interactionClick = (nameComponent, ctaName) => {
    window.gtmDataObject = window.gtmDataObject || []
    const eventData = {
        event: 'page_builder_component_interaction',
        event_raised_by: raisedByPB,
        eventCategory: 'User Engagement',
        eventAction: 'Click CTA',
        eventLabel: `Page Builder - ${nameComponent} - ${ctaName}`,
        component_name: nameComponent,
        action_type: 'web component click',
        cta_name: ctaName
    }
    window.gtmDataObject.push(eventData)
    emitCustomEvent('page_builder_component_interaction', eventData)
}

export const getProductInfo = async (sku, data) => {
    let product = data
    if (!product) {
        product = await getProduct(sku)
    }
    return product
}

export const getProductPayload = (data, actionField = {}) => {
    const {
        internationalName,
        internationalId,
        category,
        unitPrice,
        legacyId,
        name,
        technologies,
        bundled,
        inStock,
        type
    } = data

    const technology = technologies.map(item => item.split('/').pop()).join('|')
    const isDiscovery = category.toLowerCase().includes('discovery')

    return [
        // Array consists of one product details for PDP Product Detail View
        {
            ...actionField,
            name: internationalName, // '[[International Product Name]]' NIE, Contract
            id: internationalId, // '[[International Product ID]]'
            price: unitPrice, // '[[Product Price]'
            // dimension43: '[[true/false]]', // '[[true/false]]' // Signifies if this product is part of a standing order or not
            dimension44: isDiscovery.toString(), // '[[true/false]]', // Signifies if this product is part of a discovery offer
            dimension53: legacyId, // '[[Product Local market ID]]', // Local market id for product
            dimension54: name, // '[[Product Local market Name]]', // Local market name for product
            dimension55: category, // '[[Product Range]]', // Range of the product, eg: Barista Creations for Ice (Nessoft Category)
            dimension56: technology, // '[[Product Technology]]', //Product technology according to Nespresso categorization (original, vertuo, pro)
            dimension57: bundled ? 'bundle' : 'single', // '[[Product Type]]', //If the product is single or bundle
            dimension192: inStock ? 'in stock' : 'out of stock', // '[in stock]', // "in stock" or "out of stock"
            category: type, // '[[Product Category]]', //Product category according to Nespresso categorization (Nessoft type): capsule, accessory, machine
            brand: 'Nespresso' // Static value set to Nespresso
        }
    ]
}

export const trackDetailView = async (sku, data) => {
    // https://dsu-confluence.nestle.biz/display/DIANA/HQ+PB+Tracking+-+Product+Detail+View
    if (window.pbTrackDetailViewPDP || !sku || !window.napi) {
        return
    }

    const productInfo = await getProductInfo(sku, data)
    const productPayload = getProductPayload(productInfo)
    const currency = productInfo.currency

    window.gtmDataObject = window.gtmDataObject || []
    const eventData = {
        event: 'detailView',
        currencyCode: currency,
        eventRaisedBy: raisedByPB,
        ecommerce: {
            detail: {
                actionField: {},
                products: productPayload
            }
        }
    }
    window.gtmDataObject.push(eventData)
    // Track only once per page
    window.pbTrackDetailViewPDP = true
    emitCustomEvent('detailView', eventData)
}

export const trackAddToCartImpression = (product, isStickyBar) => {
    // https://dsu-confluence.nestle.biz/pages/viewpage.action?spaceKey=DIANA&title=PDP+-+Sticky+Add+To+Cart+Custom+Event

    window.gtmDataObject = window.gtmDataObject || []
    const eventData = {
        event: 'page_builder_component_interaction',
        event_raised_by: raisedByPB,
        component_name: 'before_sku_main_info',
        action_type: isStickyBar
            ? 'sticky add to cart impression'
            : 'standard add to cart impression',
        //product info
        item_id_event: product.internationalId,
        item_name_event: product.internationalName,
        item_category_event: product.type,
        item_price_event: product.price_per_capsule?.split(' ')[0],
        item_market_id_event: product.legacyId,
        item_market_name_event: product.name,
        item_range_event: product.category_name,
        item_technology_event: product.technology?.[0]?.split('/').slice(-1) ?? '',
        item_type_event: product.bundled ? 'bundle' : 'single'
    }
    window.gtmDataObject.push(eventData)
    emitCustomEvent('page_builder_component_interaction', eventData)
}

export const productImpression = async (position, list, sku, data) => {
    // https://dsu-confluence.nestle.biz/pages/viewpage.action?pageId=225612781
    // don't track already tracked products
    window.gtmDataObject = window.gtmDataObject || []
    const hasDetailView = window.gtmDataObject.find(item => item.event === 'detailView')
    const alreadyTracked = !!hasDetailView?.ecommerce?.detail?.products.find(p =>
        sku.includes(p.id)
    )
    if (alreadyTracked) {
        return
    }

    const productInfo = await getProductInfo(sku, data)
    const productPayload = getProductPayload(productInfo, { list: list, position: position })
    const currency = productInfo.currency
    const eventData = {
        event: 'impression',
        eventAction: 'Product Impression',
        currencyCode: currency,
        eventRaisedBy: raisedByPB,
        ecommerce: {
            impressions: productPayload
        }
    }
    window.gtmDataObject.push(eventData)
    emitCustomEvent('impression', eventData)
}

export const productClick = async (position, list, sku, data) => {
    // https://dsu-confluence.nestle.biz/pages/viewpage.action?pageId=225612781

    if (typeof list !== 'string') return

    const productInfo = await getProductInfo(sku, data)
    const productPayload = getProductPayload(productInfo, { position: position })
    const currency = productInfo.currency

    window.gtmDataObject = window.gtmDataObject || []
    const eventData = {
        event: 'productClick',
        eventAction: 'Product Click',
        currencyCode: currency,
        eventRaisedBy: raisedByPB,
        ecommerce: {
            click: {
                actionField: {
                    list: list
                },
                products: productPayload
            }
        }
    }
    window.gtmDataObject.push(eventData)
    emitCustomEvent('productClick', eventData)
}

export function filterTrackingFromPlpFilters(filtersSelected) {
    const grouped = groupSelectedFilters(filtersSelected)
    const groupKeys = Object.keys(grouped)
    const typesFiltersSelected = groupKeys.join('|').substring(0, 99)
    const valuesFiltersSelected = groupKeys
        .map(key => grouped[key].values.map(value => value.split('/').at(-1)).join(','))
        .join('|')
        .substring(0, 99)

    return [typesFiltersSelected, valuesFiltersSelected]
}

export const filterActionEvent = (
    actionType,
    filterType,
    filterValues,
    clickLocation,
    dataType
) => {
    // https://dsu-confluence.nestle.biz/display/DIANA/PLP+-+Filter+Revamp
    window.gtmDataObject = window.gtmDataObject || []
    const eventData = getExplicitFilterEventData(
        actionType,
        filterType,
        filterValues,
        clickLocation
    )

    window.gtmDataObject.push(eventData)
    if (window.NEXT_V1_PLP_EXPLICIT_FILTER_TRACKING && dataType) {
        window.gtmDataObject.push({
            ...window.NEXT_V1_PLP_EXPLICIT_FILTER_TRACKING,
            experiment_action: `${actionType} - ${dataType}`,
            cta_name: 'All filters'
        })
    }

    emitCustomEvent('plp_filter', eventData)
}

const getExplicitFilterEventData = (actionType, filterType, filterValues, clickLocation) => {
    return {
        event: 'plp_filter',
        event_raised_by: raisedByPB,
        action_type: actionType,
        filter_type: filterType,
        ...(filterValues ? { filter_values: filterValues } : {}),
        ...(clickLocation ? { click_location: clickLocation } : {})
    }
}

function groupSelectedFilters(filtersSelected = []) {
    return filtersSelected.reduce((grouped, { key, value }) => {
        if (!grouped[key]) grouped[key] = { key, values: [] }
        grouped[key].values.push(value.replaceAll(',', '-'))
        return grouped
    }, {})
}

function arrTolastURLElementMapper(arr) {
    if (typeof arr === 'undefined' || arr.length === 0) return undefined

    return arr.map(item => item.split('/').pop()?.trim() || '')
}

function arrToJoinedString(arr, joiner = '|') {
    if (typeof arr === 'undefined' || arr.length === 0) return undefined

    return arr.map(item => item.trim()).join(joiner)
}

function findTechnogiesText(arr) {
    if (typeof arr === 'undefined' || arr.length === 0) return undefined

    if (arr.length === 1) return cleanText(arrTolastURLElementMapper(arr)?.[0])
    else return 'multiple'
}

function cleanText(str) {
    if (typeof str === 'undefined') return undefined

    return str.toString().trim()
}

const generateEcommerceObject = async (productIds, options) => {
    const productRequests = productIds.map(SKU => getProduct(SKU))
    const productData = await Promise.all(productRequests)

    return {
        ecommerce: {
            currency: window[window.config.padl.namespace].dataLayer.app.app.currency,
            value: productData.reduce((ac, product) => {
                return ac + product.price
            }, 0),
            items: productData.map((product, index) => {
                if (product.type === 'bundle' || product.type === 'giftcard') {
                    return {
                        item_id: cleanText(product.internationalId),
                        item_name: cleanText(product.internationalName),
                        item_brand: 'nespresso',
                        item_category: cleanText(product.type),
                        index: index + 1
                    }
                }

                let output = {
                    item_id: cleanText(product.internationalId),
                    item_name: cleanText(product.internationalName),
                    item_brand: 'nespresso',
                    item_category: cleanText(product.type),
                    item_category2: arrToJoinedString(
                        arrTolastURLElementMapper(product.technologies)
                    ),
                    item_category3: cleanText(product.category),
                    price: product.price,
                    quantity: product.salesMultiple,
                    affiliation: 'nespresso online store',
                    coupon: undefined,
                    discount: undefined,
                    location_id: undefined,
                    item_list_name: cleanText(options?.listName),
                    item_list_id: cleanText(options?.listId),
                    index: index + 1,
                    item_market_id: cleanText(product.legacyId),
                    item_market_name: cleanText(product.name),
                    item_technology: findTechnogiesText(product.technologies),
                    item_range: cleanText(product.category),
                    item_discovery_offer: product.category.includes('discovery').toString(),
                    item_added_by_user: undefined,
                    item_ecotax_applicable: product?.displayEcoTax
                        ? cleanText(product?.displayEcoTax.toString())
                        : undefined,
                    item_selection_list: cleanText(arrToJoinedString(product.productSelections)),
                    item_in_stock: product?.inStock
                        ? cleanText(product?.inStock.toString())
                        : undefined,
                    item_subscription_name: undefined,
                    item_subscription_category: undefined,
                    item_subscription_price: undefined,
                    item_subscription_duration: undefined,
                    item_subscription_fee: undefined
                }

                switch (product.type) {
                    case 'capsule': {
                        const capsuleProduct = product

                        output.item_category4 = capsuleProduct.bundled ? 'bundle' : 'single'

                        output.item_type = capsuleProduct.bundled ? 'bundle' : 'single'

                        output.item_coffee_aromatic_profile = cleanText(
                            arrToJoinedString(capsuleProduct.capsuleProductAromatics)?.toLowerCase()
                        )

                        output.item_coffee_intensity =
                            cleanText(capsuleProduct.capsuleProperties.intensity) ?? undefined

                        output.item_coffee_cup_size = cleanText(
                            arrToJoinedString(capsuleProduct.capsuleCupSizes)
                        )?.toLowerCase()

                        break
                    }
                    case 'machine': {
                        const machineProduct = product

                        output.item_machine_shade = cleanText(machineProduct.colorShade?.name)

                        break
                    }
                }

                if (product.type === 'machine' || product.type === 'accessory') {
                    output.item_avg_rating = product.ratingCode
                    output.item_number_of_reviews = product.ratingCode
                }

                return output
            })
        }
    }
}

export const selectItems = async (productIds, options) => {
    const ecommerceObject = await generateEcommerceObject(productIds, options)

    const output = {
        event: 'select_item',
        event_raised_by: options?.eventRaisedBy ?? 'before_cross_sell_v3',
        click_location: options?.clickLocation ?? 'page builder cross sell quick view',
        item_list_id: options?.listId ?? 'before_cross_sell_v3',
        subscription_product_included: 'false',
        discovery_offer_included: 'false',
        ...ecommerceObject
    }

    window.gtmDataObject.push({ ecommerce: null })
    window.gtmDataObject.push(output)
}

export const viewItemList = async (productIds, options) => {
    const ecommerceObject = await generateEcommerceObject(productIds, options)

    const output = {
        event: 'view_item_list',
        event_raised_by: options?.eventRaisedBy,
        subscription_product_included: 'false',
        discovery_offer_included: 'false',
        ...ecommerceObject
    }

    if (options?.eventRaisedBy === undefined) delete output.event_raised_by

    window.gtmDataObject.push({ ecommerce: null })
    window.gtmDataObject.push(output)
}
